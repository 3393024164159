// import apiMonitor from '@lib/monitor'
import { ApisauceInstance, create } from 'apisauce'

export const createApiClient = (secretKey: string): ApisauceInstance => {
  const api = create({
    baseURL: process.env.NEXT_PUBLIC_API_URL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Basic ${Buffer.from(`${secretKey}:`).toString('base64')}`,
    },
    timeout: 30000,
  })

  // api.addMonitor(apiMonitor)

  return api
}
